<template lang="html">
  <section class="form-remember-password">
    <div class="container login column-center">
      <div class="contenedor-login">
        <router-link :to="{ name: 'login'}" class="volver">Volver</router-link>
        <h1 class="titulo-formulario-login recordar">
          Recordar contrase<span class="sombrero">n</span>a
        </h1>
        <Form :validation-schema="schema" v-slot="{ errors }">
          <div>
            <Field
              name="email"
              class="input-styled"
              placeholder="Email"
              :class="{ error: errors.email }"
              v-model="data.email"
            />
            <span class="mss-error">{{ errors.email }}</span>
          </div>
          <div>
            <button @click="onSubmit" class="boton-styled">Solicitar</button>
          </div>
        </Form>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import { Form, Field, defineRule, configure, ErrorMessage } from "vee-validate";
import { mapGetters, mapActions, mapMutations} from 'vuex';
import router from '@/router';
import store from '@/store';
  export default  {
    name: 'form-remember-password',
    props: [],
    mounted () {

    },
    data () {
      const schema = {
        email: 'required|email',
        password: 'required|min:8',
        name: 'required'
      };
      return {
        schema,
         data : {
           email :'',
         }
      };
    },
    components: {
      Field,
      Form
    },
    methods: {
    ...mapMutations({
       launchAlert: "alert/launchAlert",
       }),
    ...mapActions({
        remeberPasword : 'auth/remeberPasword',
      }),
      onSubmit(){
        this.remeberPasword(this.data).then(r => {
          if (r) {
            this.launchAlert('Revise su email.');
            this.$router.push({ name: 'login'});
          } 
        })
      },
    }
}
</script>

<style scoped lang="scss">
.mss-error {
  display: inline-block;
  text-align: left;
  width: 100%;
  max-width: 285px;
  color: #d32626;
}
.contenedor-login .input-styled {
  margin-bottom: 0;
}
</style>
